import React from "react";
import Movie from "./movie";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const MovieList = ({ movieIds, className, onMovieDetailClick }) => {
  const handleClick = (id) => {
    if (onMovieDetailClick) {
      onMovieDetailClick(id);
    }
  };

  return (
    <>
        {movieIds.length === 0 && <p className="movies-no-item">Nic tu nemáme</p>}
        <ResponsiveMasonry 
          columnsCountBreakPoints={{
            0: 1,
            320: 2,
            730: 3,
            910: 4,
            1100: 5,
            1300: 6,
            1500: 7,
            1700: 8,
            1800: 9,
          }}
        >
          <Masonry gutter="1em" sequential="true" className={className}>
            {movieIds.map((id) => (
              <Movie id={id} key={`movie-key-${id}`} onClick={() => handleClick(id)} />
            ))}
          </Masonry>
        </ResponsiveMasonry>
    </>
  );
};

export default MovieList;
