const neutralizeBack = (callback) => {
  let savedScrollPosition = 0;

  const saveScrollPosition = () => {
    savedScrollPosition = window.scrollY || document.documentElement.scrollTop;
  };

  const restoreScrollPosition = () => {
    window.scrollTo(0, savedScrollPosition);
  };

  window.history.pushState(null, "", window.location.href);

  window.onpopstate = () => {
    setTimeout(() => {
      restoreScrollPosition();
    }, 100);
   
    window.history.pushState(null, "", window.location.href);
    callback();
  };

  saveScrollPosition();
  window.addEventListener("load", restoreScrollPosition);
};

const revivalBack = () => {
  window.onpopstate = undefined;
  window.history.back();
};

export { neutralizeBack, revivalBack }