import React from "react";

function Credits(props) {
  const credits = props.credits;

  if (credits && credits.length > 0) {
    return <ul className="movie-datail-credits">
      {credits.map((credit, index) => (
        <li key={index} className="credit">
          <span className="credit-title">{credit.title}:</span>{"\u00A0"}
          {credit.name.split(", ").map((person, subIndex, array) => (
            <a
              key={subIndex}
              href={`https://www.google.com/search?q=${encodeURIComponent(person)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="credit-name">{person}{subIndex < array.length - 1 && ", "}</span>
            </a>
          ))}
        </li>
      ))}
    </ul>
  }

  return null;
}

export default Credits;
